
@import 'antd/dist/reset.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

select{
  height: 40px;
  border-radius: 6px;
  font-weight: 150;
}

/*

.ant-layout {
  background-color: transparent;
  background-image: url('https://images.unsplash.com/photo-1614292264554-7dca1d6466d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  background-image: url('https://images.unsplash.com/photo-1614292264554-7dca1d6466d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
*/

html {
  height: 100%;
}

.overlay {
  background: #000000 0% 0% no-repeat padding-box;
opacity: 0.65;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}